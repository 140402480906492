import {EcomComponent, withEcomPlatformData} from '@wix/ecom-platform-sdk'

import {EXPERIMENTS, PARENT_APP_DEF_ID} from '../utils/constants'
import {isExperimentEnabled} from '../utils/experiments'

import {editorReady as editorReadyImplementation} from './editorReady'
import {getAppManifest as getAppManifestImplementation} from './getAppManifest'
import {onEvent as onEventImplementation} from './onEvent'
import {exports as exportsImplementation} from './exports'

export const platformApp = withEcomPlatformData(
  {
    editorReady: editorReadyImplementation,
    getAppManifest: getAppManifestImplementation,
    onEvent: onEventImplementation,
    exports: exportsImplementation,
  } as any,
  async () => {
    const shouldInstallEcom = await isExperimentEnabled(EXPERIMENTS.shouldEnableEcom)

    return {
      ecomComponents: {[EcomComponent.CHECKOUT]: {isInstalled: true}},
      shouldInstallMembersArea: false,
      shouldSkipEcomInstallation: !shouldInstallEcom,
      managingAppId: PARENT_APP_DEF_ID,
    }
  },
)
