import {ReservationLocation} from '@wix/ambassador-table-reservations-v1-reservation-location/build/cjs/types.impl'
import {ComponentRef, FlowEditorSDK} from '@wix/yoshi-flow-editor'
import {getPanelUrl} from '@wix/yoshi-flow-editor/utils'

const BM_APP_URL_PREFIX = `/wix-table-reservations/table-reservations`

export const openReservationsBmOverEditor = (editorSDK: FlowEditorSDK) =>
  editorSDK.editor.openDashboardPanel('', {
    url: BM_APP_URL_PREFIX,
    closeOtherPanels: false,
  })

export const openWidgetSettingsPanel = (editorSDK: FlowEditorSDK, componentRef: ComponentRef) =>
  editorSDK.editor.openSettingsPanel('', {componentRef})

export const openSettingsBmOverEditor = (editorSDK: FlowEditorSDK) =>
  editorSDK.editor.openDashboardPanel('', {
    url: `${BM_APP_URL_PREFIX}/settings`,
    closeOtherPanels: false,
  })

export const openStep2FormSettingsBmOverEditor = (
  editorSDK: FlowEditorSDK,
  locationID: ReservationLocation['id'],
) =>
  editorSDK.editor.openDashboardPanel('', {
    url: `${BM_APP_URL_PREFIX}/settings/${locationID}/reservation-form`,
    closeOtherPanels: false,
  })

export const openBmLocationsSettings = (editorSDK: FlowEditorSDK) =>
  editorSDK.editor.openDashboardPanel('', {
    url: '/business-info',
    closeOtherPanels: false,
  })

export const openAddonsModal = (editorSDK: FlowEditorSDK, t) =>
  editorSDK.editor.openModalPanel('', {
    url: getPanelUrl('ReservationAddOn', 'Addons'),
    width: 600,
    height: 592,
    title: t('editor-reservations.sidebar.addons'),
  })

export const EDITOR_OPEN_TABLE_RESERVATION_WIDGET_SETTINGS_PANEL =
  'EDITOR_OPEN_TABLE_RESERVATION_WIDGET_SETTINGS_PANEL'
